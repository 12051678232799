import React, { Component } from 'react'
import Join from '../components/Join'
import Layout from '../layouts/Layout'

export default class joinUs extends Component {
    render() {
        return (
            <Layout>
                <Join />
            </Layout>
        )
    }
}
